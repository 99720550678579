import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogService, DynamicDialogModule, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { InputMaskModule } from 'primeng/inputmask';
import { ChipsModule } from 'primeng/chips';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToolbarModule } from 'primeng/toolbar';

import { StoreDirectoryComponent } from './store-directory/store-directory.component';
import { StoreDirectoryDialogComponent } from './store-directory-dialog/store-directory-dialog.component';
import { StoreService } from './stores/stores.service';
import { SalespeopleDirectoryComponent } from './salespeople-directory/salespeople-directory.component';
import { CorporateDirectoryComponent } from './corporate-directory/corporate-directory.component';
import { CorporateDirectoryDialogComponent } from './corporate-directory-dialog/corporate-directory-dialog.component';
import { CorpEmployeeService } from './corp-employee/corp-employee.service';
import { PhonePipe } from './phone.pipe';
import { ArrayBreakoutPipe } from './array-breakout.pipe';
import { environment } from '../environments/environment';

const config = {
  config: {
    url: 'http://id.stmtires.com/auth',
    realm: 'STM_Dev',
    clientId: 'STM_Company_Directory',
    enableBearerInterceptor: true,
  },
};

@NgModule({
  declarations: [
    AppComponent,
    StoreDirectoryComponent,
    StoreDirectoryDialogComponent,
    SalespeopleDirectoryComponent,
    CorporateDirectoryComponent,
    CorporateDirectoryDialogComponent,
    PhonePipe,
    ArrayBreakoutPipe,
  ],
  imports: [
    FormsModule,
    DropdownModule,
    CheckboxModule,
    InputMaskModule,
    ChipsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    TableModule,
    DialogModule,
    ButtonModule,
    InputSwitchModule,
    InputTextareaModule,
    AutoCompleteModule,
    DynamicDialogModule,
    HttpClientModule,
    ToastModule,
    KeycloakAngularModule,
    SelectButtonModule,
    ToolbarModule,
  ],
  entryComponents: [
    StoreDirectoryDialogComponent,
    CorporateDirectoryDialogComponent,
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: (keycloak: KeycloakService) => () => keycloak.init({
      config: environment.keycloak,
      enableBearerInterceptor: true,
      initOptions: { checkLoginIframe: false,
      }}),
    multi: true,
    deps: [KeycloakService],
  },
    StoreService,
    CorpEmployeeService,
    MessageService,
    DialogService,
    StoreDirectoryDialogComponent,
    CorporateDirectoryDialogComponent,
    DynamicDialogRef,
    DynamicDialogConfig],
  bootstrap: [AppComponent],
})
export class AppModule { }
