import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-salespeople-directory',
  templateUrl: './salespeople-directory.component.html',
  styleUrls: ['./salespeople-directory.component.scss'],
})
export class SalespeopleDirectoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
