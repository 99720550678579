import { NgModule } from '@angular/core';
import { Route, Routes, RouterModule } from '@angular/router';
import { StoreDirectoryComponent } from './store-directory/store-directory.component';
import { SalespeopleDirectoryComponent } from './salespeople-directory/salespeople-directory.component';
import { CorporateDirectoryComponent } from './corporate-directory/corporate-directory.component';
import { AuthGuard } from './auth.guard';

const notreal: Route = {};
const routes: Routes = [
/*  {
    path: '',
    component: ,
    children: [
      { path: '', component:  },
      { path: '', component:  },
    ],
  },*/
  { path: '', redirectTo: 'stores', pathMatch: 'full' },
  { path: 'stores', canActivate: [AuthGuard], component: StoreDirectoryComponent },
  { path: 'salespeople', component: SalespeopleDirectoryComponent },
  { path: 'corporate', canActivate: [AuthGuard], component: CorporateDirectoryComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
