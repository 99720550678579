import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CorpEmployee } from './corp-employee.interface';
import { environment } from '../../environments/environment';

@Injectable()
export class CorpEmployeeService {

  private apiURL = `${environment.apiURL}/corporate`;

  constructor(private http: HttpClient) { }

  async getCorpEmployees() {
    return this.http.get<any>(this.apiURL)
      .toPromise()
      .then(res => <CorpEmployee[]>res)
      .then((data) => { return data; });
  }

  async getSingleEmployee(corpEmpID: string) {
    return this.http.get<any>(`${this.apiURL}/${corpEmpID}`)
    .toPromise()
    .then(res => <CorpEmployee>res)
    .then((data) => { return data; });
  }

  async submitNewEmployee(corpEmpJSON: any) {
    return this.http.post<any>(`${this.apiURL}`, corpEmpJSON)
    .toPromise()
    .then(res => <CorpEmployee>res)
    .then((data) => { return data; });
  }

  async submitAlteredEmployee(corpEmpJSON: any) {
    return this.http.put<any>(`${this.apiURL}/${corpEmpJSON._id}`, corpEmpJSON)
    .toPromise()
    .then(res => <CorpEmployee>res)
    .then((data) => { return data; });
  }

  async deleteEmployee(corpEmpJSON: any) {
    return this.http.delete<any>(`${this.apiURL}/${corpEmpJSON._id}`)
    .toPromise()
    .then((res) => { return res; });
  }

  async emailSuggestedEdit(corpEmpJSON: any) {
    return this.http.post<any>(`${this.apiURL}/contact_us`, corpEmpJSON)
    .toPromise()
    .then(res => <CorpEmployee>res)
    .then((data) => { return data; });
  }

  async exportToExcel() {
    const downloadLink = document.createElement('a');
    downloadLink.href = `${this.apiURL}/export`;
    downloadLink.target = '_blank';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}
