import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { Store } from '../stores/stores.interface';
import { StoreService } from '../stores/stores.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-store-directory-dialog',
  templateUrl: './store-directory-dialog.component.html',
  styleUrls: ['./store-directory-dialog.component.scss'],
  providers: [DialogService],
})
export class StoreDirectoryDialogComponent implements OnInit {

  clientDomain: string;
  chosenStore: Store;
  filteredStores: Store[];
  // ===================starting form for dialog
  dialogForm: FormGroup = this.initStoreGroupObject();

  constructor(
    private fb: FormBuilder,
    private storeService: StoreService,
    public dialogService: DialogService,
    private messageService: MessageService,
    public ref: DynamicDialogRef,
    public ref2: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private keycloakService: KeycloakService,
    ) { }

  ngOnInit(): void {
  // this.getStoresFromService();
    this.clientDomain = (window.location.href.includes('stmpilot')) ? 'STMP' : 'STM';
  }

  get userCanEdit() {
    const userRoles = (this.keycloakService.getUserRoles());
    return (userRoles.includes('directory-admin'));
  }

  initStoreGroupObject(store?: Store) {
    return this.fb.group({
      _id: (store !== undefined) ? store._id : null,
      storeNumber: new FormControl((store !== undefined) ? store.storeNumber : '',
        [Validators.required, Validators.pattern(/^[0-9]+$/)]),
      storeName: new FormControl((store !== undefined) ? store.storeName : ''),
      streetAddress: new FormControl((store !== undefined) ? store.streetAddress : ''),
      city: new FormControl((store !== undefined) ? store.city : ''),
      state: new FormControl((store !== undefined) ? store.state : ''),
      zipCode: new FormControl((store !== undefined) ? store.zipCode : '', Validators.pattern(/^\d{5}$|^\d{5}-\d{4}$/)),
      storePhone: new FormControl((store !== undefined) ? store.storePhone : ''),
      storeFax: new FormControl((store !== undefined) ? store.storeFax : ''),
      storeEmail: new FormControl((store !== undefined) ? store.storeEmail : '', Validators.email),
      retail: new FormControl((store !== undefined) ? store.retail : false),
      maintPlus: new FormControl((store !== undefined) ? store.maintPlus : false),
      commercial: new FormControl((store !== undefined) ? store.commercial : false),
      storeManager: new FormControl((store !== undefined) ? store.storeManager : ''),
      managerEmail: new FormControl((store !== undefined) ? store.managerEmail : '', Validators.email),
      managerCell: new FormControl((store !== undefined) ? store.managerCell : ''),
      retread: new FormControl((store !== undefined) ? store.retread : false),
      retreadParent: new FormControl((store !== undefined) ? store.retreadParent : ''),
      weekdayHours: new FormControl((store !== undefined) ? store.weekdayHours : ''),
      weekendHours: new FormControl((store !== undefined) ? store.weekendHours : ''),
      websiteQuoteEmail: new FormControl((store !== undefined) ? store.websiteQuoteEmail : ''),
      websiteQuoteForwards: new FormControl((store !== undefined) ? store.websiteQuoteForwards : ''),
      location: new FormControl((store !== undefined) ? store.location : ''),
    });
  }

  async submitNewStore(storeFormGroup: FormGroup) {
    if (this.clientDomain === 'STMP') {
      storeFormGroup.get('commercial').setValue(true);
    }
    const newStoreFromServer = await this.storeService.submitNewStore(storeFormGroup.value);
    this.closeDialog(newStoreFromServer);
  }

  async submitAlteredStore(storeFormGroup: any) {
    const updatedStoreFromServer = await this.storeService.submitAlteredStore(storeFormGroup.value);
    this.closeDialog(updatedStoreFromServer);
  }

  async deleteStore(storeFormGroup: any) {
    const deletedStore = await this.storeService.deleteStore(storeFormGroup.value);
    this.closeDialog(deletedStore);
  }

  async getSingleStore(store: Store) {
    const storeFromDB = await this.storeService.getSingleStore(store._id);
  }

  async chooseStoreForSuggestion(chosenStore: any) {
    this.showSuggestDialog(this.chosenStore);
    this.closeDialog2(chosenStore);
  }

  showSuggestDialog(chosenStore?: Store) {
    const storeData = {
      store: (chosenStore) ? this.initStoreGroupObject(chosenStore) : this.initStoreGroupObject(),
      dialogType: 'suggestion',
    };

    this.ref = this.dialogService.open(StoreDirectoryDialogComponent, {
      data: storeData,
      showHeader: true,
      header: 'Suggest Changes',
      width: 'auto',
      closable: true,
      closeOnEscape: true,
      dismissableMask: true,
      footer: 'Suggestions will be submitted for review',
    });

    this.ref.onClose.subscribe((store: any) => {
      if (store) {
        this.messageService.add({
          severity:'info',
          summary: 'Suggestion emailed to admin',
          // detail: `${store.storeNumber} ${store.storeName}`
        });
      }
    });
  }

  filterStores(event: { query: any }) {
    const filtered: Store[] = [];
    const query = event.query;
    for (let index = 0; index < this.config.data.stores.length; index++) {
      const store = this.config.data.stores[index];
      if (store.storeNumber.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(store);
      }
    }
    this.filteredStores = filtered;
  }

  async submitSuggestion(suggestionFormGroup: any) {
    const suggestionResponse = await this.storeService.emailSuggestedEdit(suggestionFormGroup);
    this.closeDialog(suggestionResponse);
  }

  closeDialog(result?: any) {
    this.ref.close(result);
  }

  closeDialog2(result?: any) {
    this.ref2.close(result);
  }

  /*ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }*/

  consoleLogFromPage(x) {
    console.log(x);
  }

}
