import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayBreakout',
})
export class ArrayBreakoutPipe implements PipeTransform {

  transform(value: string[]): any {
    try {
      return value.join(', ');
    } catch (error) {
      console.error(error);
      return value;
    }
  }
}
