const keycloakDev = {
  url: 'https://id.stmtires.com/auth',
  realm: 'STM_Dev',
  clientId: 'STM_Company_Directory',
};

export const environment = {
  production: false,
  keycloak: keycloakDev,
  apiURL: 'https://api.directory.dev.stmtires.com',
  homeURL: 'https://portal.dev.stmtires.com',
  directoryLogo: '../assets/img/STMDirectoriesLogo_HOR - RGB (2).svg',
};
