<div class="corporate-directory-dialog-container">
  <form [formGroup]="dialogForm">
    <div *ngIf="config.data.dialogType === 'readOnly'" class="readOnly" [formGroup]="config.data.employee">

      <div class="p-grid"><!--First Row-->

        <div class="p-lg-4 p-md-4 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Name: </div>
          <div class="box p-text-center p-text-bold">{{config.data.employee.controls.name.value}}</div>
        </div>

        <div class="p-lg-4 p-md-4 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Email: </div> 
          <div class="box p-text-truncate p-text-center p-text-bold"><a href="mailto:{{config.data.employee.controls.email.value}}"
            >{{config.data.employee.controls.email.value}}</a></div>
        </div>

        <div class="p-lg-4 p-md-4 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Department: </div> 
          <div class="box p-text-center p-text-bold">{{config.data.employee.controls.department.value}}</div>
        </div>

      </div><!--First Row-->

      <div class="p-grid"><!--Second Row-->

        <div class="p-lg-3 p-md-3 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Office Phone: </div>
          <div class="box p-text-center p-text-bold"><a href="tel:{{config.data.employee.controls.officePhone.value}}"
            >{{config.data.employee.controls.officePhone.value | phone:"US"}}</a></div>
        </div>

        <div class="p-lg-3 p-md-3 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Extension: </div>
          <div class="box p-text-center p-text-bold">{{config.data.employee.controls.extension.value}}</div>
        </div>

        <div class="p-lg-3 p-md-3 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Cell Phone: </div> 
          <div class="box p-text-center p-text-bold"><a href="tel:{{config.data.employee.controls.cellPhone.value}}"
            >{{config.data.employee.controls.cellPhone.value | phone:"US"}}</a></div>
        </div>

        <div class="p-lg-3 p-md-3 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Fax: </div>
          <div class="box p-text-center p-text-bold">{{config.data.employee.controls.fax.value | phone:"US"}}</div>
        </div>

      </div><!--Second Row-->

      <div class="p-grid">

        <div class="p-col-12">
          <div class="p-text-left p-text-bold">Areas of Responsibility: </div>
          <div class="box p-text-center p-text-bold">{{config.data.employee.controls.areasOfResponsibility.value | arrayBreakout}}</div>
        </div>

      </div>

    </div>
    <!--^^^ Read Only| vvv Editable-->
    <div *ngIf="config.data.dialogType === 'edit' || 
      config.data.dialogType === 'suggestion' || 
      config.data.dialogType === 'create'" class="edit" [formGroup]="config.data.employee">
      <!--suggest? edit create stuff-->
      <div class="p-grid"><!--Row 1-->
        
        <div class="p-lg-4 p-md-4 p-sm-12 p-col-12">
          <label for="name">Name
            <span style="color:red">*</span>
          </label>
          <input type="text" pInputText formControlName="name"> 
        </div>

        <div class="p-lg-4 p-md-4 p-sm-12 p-col-12">
          <label for="email">Email
            <span style="color:red">*</span>
          </label>
          <label for="email" *ngIf="!config.data.employee.controls.email.valid" style="color: red">   Must follow: user@domain.com</label>
          <input type="text" pInputText formControlName="email"> 
        </div>

        <div class="p-lg-2 p-md-2 p-sm-12 p-col-12">
          <label for="department">Department</label>
          <p-autoComplete formControlName="department" [suggestions]="filteredDepartments"
           (completeMethod)="filterDepartments($event)" [forceSelection]="true" ></p-autoComplete>
        </div>

        <div class="p-lg-2 p-md-2 p-sm-12 p-col-12">
          <label for="title">Department Head?</label>
          <p-selectButton [options]="titleOptions" formControlName="title" optionLabel="label" optionValue="value"></p-selectButton>
        </div>

      </div><!--Row 1-->

      <div class="p-grid"><!--Row 2-->
        
        <div class="p-lg-3 p-md-3 p-sm-12 p-col-12">
          <div>
            <label for="officePhone">Office Phone</label>
          </div>
          <p-inputMask formControlName="officePhone" mask="(999) 999-9999" class="input-mask" unmask="true"></p-inputMask>
        </div>

        <div class="p-lg-3 p-md-3 p-sm-12 p-col-12">
          <div>
            <label for="extension">Extension</label>
          </div>
          <p-inputMask formControlName="extension" mask="9999" class="input-mask" unmask="true"></p-inputMask>
        </div>

        <div class="p-lg-3 p-md-3 p-sm-12 p-col-12">
          <div>
            <label for="cellPhone">Cell Phone</label>
          </div>
          <p-inputMask formControlName="cellPhone"  mask="(999) 999-9999" class="input-mask" unmask="true"></p-inputMask> 
        </div>

        <div class="p-lg-3 p-md-3 p-sm-12 p-col-12">
          <div>
            <label for="fax">Fax</label>
          </div>
          <p-inputMask formControlName="fax"  mask="(999) 999-9999" class="input-mask" unmask="true"></p-inputMask> 
        </div>

      </div><!--Row 2-->

      <div class="p-grid"><!--Row 3-->
        
        <div class=" p-col-12">
          <div>
            <label for="areasOfResponsibility">Areas of Responsibility</label>
          </div>
          <p-chips placeholder="Press enter after each responsibility entered."  formControlName="areasOfResponsibility"></p-chips> 
        </div>

      </div><!--Row 3-->

      <div class="ui-dialog-footer" style="text-align: right; margin-top:.5em;">
        <button pButton type="button" icon="far fa-skull-crossbones" iconPos="left" label="DELETE" (click)="deleteEmployee(config.data.employee)"
          class="p-button-rounded" *ngIf="config.data.dialogType === 'edit'"></button>
        <button pButton type="button" icon="far fa-check" iconPos="left" label="Submit" (click)="submitAlteredEmployee(config.data.employee)"
          class="p-button-rounded" *ngIf="config.data.dialogType === 'edit'"></button>
        <button pButton type="button" icon="far fa-check" iconPos="left" label="Submit" (click)="submitSuggestion(config.data.employee.value)"
          class="p-button-rounded" *ngIf="config.data.dialogType === 'suggestion'"></button>
        <button pButton type="button" icon="far fa-check" iconPos="left" label="Submit" (click)="submitNewEmployee(config.data.employee)"
          class="p-button-rounded" *ngIf="config.data.dialogType === 'create'"></button>
      </div>

    </div>

    <div *ngIf="config.data.dialogType === 'suggestPrompt'" class="suggestPrompt">
      <div style="height:300px">
        <p-autoComplete placeholder="Search for Employee" field="name" [(ngModel)]="chosenEmployee" [ngModelOptions]="{standalone: true}" [suggestions]="filteredEmployees"
          (completeMethod)="filterEmployees($event)" [forceSelection]="true" ></p-autoComplete>
          <button pButton type="button" label="OK" class="p-button-text" (click)="chooseEmployeeForSuggestion($event)"></button>
      </div>
      <div >
        <button pButton style="width:100%"type="button" label="Request new employee entry" class="p-button-text" (click)="chooseEmployeeForSuggestion()"></button>
      </div>
    </div>
  </form>
</div>
