import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Store } from 'src/app/stores/stores.interface';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { StoreDirectoryDialogComponent } from '../store-directory-dialog/store-directory-dialog.component';
import { StoreService } from '../stores/stores.service';
import * as _ from 'lodash';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-store-directory',
  templateUrl: './store-directory.component.html',
  styleUrls: ['./store-directory.component.scss'],
  providers: [DialogService, MessageService],
})
export class StoreDirectoryComponent implements OnInit {

  selectedStore: Store;
  searchableFields: string[];
  dialogType: 'readOnly'|'edit'|'create'|'suggestion'|'suggestPrompt';
  editMode = false;
  disablePagination = true;
  suggestMode = false;
  stores: Store[];
  storeMap: { [_id: string]: Store } = {};

  constructor(
    private dialogService: DialogService,
    private storeService: StoreService,
    private messageService: MessageService,
    private storeDialog: StoreDirectoryDialogComponent,
    private keycloakService: KeycloakService,
  ) { }

  ref: DynamicDialogRef;

  ngOnInit(): void {
    this.getStores();
  }

  get userCanEdit() {
    const userRoles = (this.keycloakService.getUserRoles());
    return (userRoles.includes('directory-admin'));
  }

  toggleButtonWidth() {
    return (this.editMode) ? '50%' : '100%';
  }

  async getStores() {
    this.stores = await this.storeService.getStores()
    .then((data) => {
      this.searchableFields = data.length ? Object.keys(data[0]) : [];
      return data;
    });
    for (const store of this.stores) {
      this.storeMap[store._id] = store;
    }
    return this.storeMap;
  }

  addStore(newStore: Store, _id: string) {
    this.storeMap[_id] = newStore;
  }

  removeStore(_id: string) {
    delete this.storeMap[_id];
  }

  // Show the dynamic dialog with selected-store, readOnly data populating it.
  showReadDialog(chosenStore: string) {
    this.dialogType = 'readOnly';
    console.log(chosenStore);
    const storeData = {
      store: this.storeDialog.initStoreGroupObject(this.storeMap[chosenStore]),
      dialogType: this.dialogType,
    };

    this.ref = this.dialogService.open(StoreDirectoryDialogComponent, {
      data:  storeData,
      showHeader: true,
      header: `${storeData.store.controls.storeNumber.value} ${storeData.store.controls.storeName.value}`,
      footer: '',
      width: '70%',
      closable: true,
      closeOnEscape: true,
      dismissableMask: true,
    });
  }

  showEditDialog(chosenStore: string) {
    this.dialogType = 'edit';
    const storeData = {
      store: this.storeDialog.initStoreGroupObject(this.storeMap[chosenStore]),
      dialogType: this.dialogType,
    };
    this.ref = this.dialogService.open(StoreDirectoryDialogComponent, {
      data:  storeData,
      showHeader: true,
      header: `EDITING - ${storeData.store.controls.storeName.value} `,
      width: 'auto',
      closable: true,
      closeOnEscape: true,
      dismissableMask: true,
    });

    this.ref.onClose.subscribe((store: any) => {
      if (store === undefined) { return; }
      console.log(store);
      if (store.storeNumber !== undefined) {
        this.messageService.add({
          severity:'success',
          summary: 'Edits Stored to Database',
          detail: `${store.storeNumber} ${store.storeName}`});
      } else if (store.deletedCount >= 1) {
        this.removeStore(chosenStore);
        this.messageService.add({
          severity:'success',
          summary: 'Store Deleted from Database',
        });
      }
      this.ngOnInit();
    });
  }

  showCreateDialog() {
    this.dialogType = 'create';
    const storeData = {
      store: this.storeDialog.initStoreGroupObject(),
      dialogType: this.dialogType,
    };
    delete storeData.store.controls._id;

    this.ref = this.dialogService.open(StoreDirectoryDialogComponent, {
      data: storeData,
      showHeader: true,
      header: 'CREATING Store',
      width: 'auto',
      closable: true,
      closeOnEscape: true,
      dismissableMask: true,
      footer: '',
    });

    this.ref.onClose.subscribe((store: any) => {
      console.log(store);
      if (store) {
        this.addStore(store, store._id);
        this.messageService.add({
          severity:'info',
          summary: 'Store Created',
          detail: `${store.storeNumber} ${store.storeName}`});
      }
      this.ngOnInit();
    });
  }

  showSuggestPrompt() {
    this.dialogType = 'suggestPrompt';
    const dialogData = {
      stores: this.stores,
      dialogType: this.dialogType,
    };
    this.ref = this.dialogService.open(StoreDirectoryDialogComponent, {
      data: dialogData,
      showHeader: true,
      header: 'Suggest Changes for which store?',
      width: 'auto',
      baseZIndex: 100,
      closable: true,
      closeOnEscape: true,
      dismissableMask: true,
      footer: '',
    });
  }

  async exportDirectory() {
    const stores = await this.storeService.getStores();
    for (const store of stores) {
      delete store._id;
      delete store.__v;
    }
    const wbFromServer = this.storeService.exportToExcel();
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }

  consoleLogFromPage(x: any) {
    console.log(x);
  }

}
