import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CorpEmployee } from 'src/app/corp-employee/corp-employee.interface';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CorporateDirectoryDialogComponent } from '../corporate-directory-dialog/corporate-directory-dialog.component';
import { CorpEmployeeService } from '../corp-employee/corp-employee.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-corporate-directory',
  templateUrl: './corporate-directory.component.html',
  styleUrls: ['./corporate-directory.component.scss'],
  providers: [DialogService, MessageService],
})
export class CorporateDirectoryComponent implements OnInit {

  selectedEmployee: CorpEmployee;
  searchableFields: string[] = [
    'areasOfResponsibility',
    'cellPhone',
    'department',
    'email',
    'extension',
    'fax',
    'name',
    'officePhone',
    'title',
  ];
  dialogType: 'readOnly'|'edit'|'create'|'suggestion'|'suggestPrompt';
  editMode = false;
  corpEmployees: CorpEmployee[];
  corpEmpMap: { [_id: string]: CorpEmployee } = {};

  constructor(
    private dialogService: DialogService,
    private corpEmpService: CorpEmployeeService,
    private messageService: MessageService,
    private corpEmpDialog: CorporateDirectoryDialogComponent,
    private keycloakService: KeycloakService,
  ) { }

  ref: DynamicDialogRef;

  ngOnInit(): void {
    this.getCorpEmployees();
  }

  get userCanEdit() {
    const userRoles = (this.keycloakService.getUserRoles());
    return (userRoles.includes('directory-admin'));
  }

  onSubmit() {

  }

  toggleButtonWidth() {
    return (this.editMode) ? '50%' : '100%';
  }

  async getCorpEmployees() {
    this.corpEmployees = await this.corpEmpService.getCorpEmployees();

    for (const emp of this.corpEmployees) {
      this.corpEmpMap[emp._id] = emp;
    }
    return this.corpEmpMap;
  }

  addCorpEmployee(newCorpEmp: CorpEmployee, _id: string) {
    this.corpEmpMap[_id] = newCorpEmp;
  }

  removeCorpEmployee(_id: string) {
    delete this.corpEmpMap[_id];
  }

  showReadDialog(chosenEmployee: string) {
    this.dialogType = 'readOnly';
    console.log(chosenEmployee);
    const corpEmpData = {
      employee: this.corpEmpDialog.initCorpEmpGroupObject(this.corpEmpMap[chosenEmployee]),
      dialogType: this.dialogType,
    };
    const headerText = (corpEmpData.employee.controls.title.value === 'Department Head') ?
      `${corpEmpData.employee.controls.name.value} - ${corpEmpData.employee.controls.title.value}` :
      corpEmpData.employee.controls.name.value;

    this.ref = this.dialogService.open(CorporateDirectoryDialogComponent, {
      data:  corpEmpData,
      showHeader: true,
      header: headerText,
      footer: '',
      width: '70%',
      closable: true,
      closeOnEscape: true,
      dismissableMask: true,
    });
  }

  showEditDialog(chosenEmployee: string) {
    this.dialogType = 'edit';
    const employeeData = {
      employee: this.corpEmpDialog.initCorpEmpGroupObject(this.corpEmpMap[chosenEmployee]),
      dialogType: this.dialogType,
    };
    this.ref = this.dialogService.open(CorporateDirectoryDialogComponent, {
      data:  employeeData,
      showHeader: true,
      header: `EDITING - ${employeeData.employee.controls.name.value} `,
      width: 'auto',
      closable: true,
      closeOnEscape: true,
      dismissableMask: true,
    });

    this.ref.onClose.subscribe((employee: any) => {
      if (employee === undefined) { return; }
      console.log(employee);
      if (employee.name !== undefined) {
        // need an update function
        this.messageService.add({
          severity:'success',
          summary: 'Edits Stored to Database',
          detail: `${employee.name} `});
      } else if (employee.deletedCount >= 1) {
        this.removeCorpEmployee(chosenEmployee);
        this.messageService.add({
          severity:'success',
          summary: 'Employee Deleted from Database',
        });
      }
      this.ngOnInit();
    });
  }

  showCreateDialog() {
    this.dialogType = 'create';
    const employeeData = {
      employee: this.corpEmpDialog.initCorpEmpGroupObject(),
      dialogType: this.dialogType,
    };
    delete employeeData.employee.controls._id;

    this.ref = this.dialogService.open(CorporateDirectoryDialogComponent, {
      data: employeeData,
      showHeader: true,
      header: 'CREATING Employee',
      width: 'auto',
      closable: true,
      closeOnEscape: true,
      dismissableMask: true,
      footer: '',
    });

    this.ref.onClose.subscribe((employee: any) => {
      console.log(employee);
      if (employee) {
        this.addCorpEmployee(employee, employee._id);
        this.messageService.add({
          severity:'info',
          summary: 'Employee Created',
          detail: employee.name});
        this.ngOnInit();
      }
    });
  }

  showSuggestPrompt() {
    this.dialogType = 'suggestPrompt';
    const dialogData = {
      employees: this.corpEmployees,
      dialogType: this.dialogType,
    };
    this.ref = this.dialogService.open(CorporateDirectoryDialogComponent, {
      data: dialogData,
      showHeader: true,
      header: 'Suggest Changes for which employee?',
      width: 'auto',
      baseZIndex: 100,
      closable: true,
      closeOnEscape: true,
      dismissableMask: true,
      footer: '',
    });

  }

  async exportDirectory() {
    const employees = await this.corpEmpService.getCorpEmployees();
    for (const employee of employees) {
      delete employee._id;
      delete employee.__v;
    }
    const wbFromServer = this.corpEmpService.exportToExcel();
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }

  consoleLogFromPage(x: any) {
    console.log(x);
  }

}
