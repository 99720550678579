import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { CorpEmployee } from '../corp-employee/corp-employee.interface';
import { CorpEmployeeService } from '../corp-employee/corp-employee.service';

@Component({
  selector: 'app-corporate-directory-dialog',
  templateUrl: './corporate-directory-dialog.component.html',
  styleUrls: ['./corporate-directory-dialog.component.scss'],
  providers: [DialogService],
})
export class CorporateDirectoryDialogComponent implements OnInit {

  chosenEmployee: CorpEmployee;
  filteredEmployees: CorpEmployee[];

  dialogForm: FormGroup = this.initCorpEmpGroupObject();

  departmentList: string[] = [
    'Accounting',
    'Accounts Payable',
    'Accounts Receivable/Credit',
    'Call Center',
    'Government Sales',
    'Human Resources/Risk Management',
    'Inventory',
    'IT',
    'Legal',
    'Marketing/Advertising',
    'Payroll',
    'Print Shop',
    'Strategic Account Managers',
    'Trainers',
    'Vehicles/Tire Service Equipment',
    'Building Construction & Maintenance',
    'Southern Tire Mart at Pilot',
  ];
  filteredDepartments: string[];
  titleOptions = [{ label: 'Yes', value: 'Department Head' }, { label: 'No', value: '' }];

  constructor(
    private fb: FormBuilder,
    private corpEmpService: CorpEmployeeService,
    public dialogService: DialogService,
    private messageService: MessageService,
    public ref: DynamicDialogRef,
    public ref2: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) { }

  ngOnInit(): void {
  }

  initCorpEmpGroupObject(employee?: CorpEmployee) {
    return this.fb.group({
      _id: (employee !== undefined) ? employee._id : null,
      department: new FormControl((employee !== undefined) ? employee.department : ''),
      // departmentHead: string;
      name: new FormControl((employee !== undefined) ? employee.name : '', [Validators.required]),
      email: new FormControl((employee !== undefined) ? employee.email : '', [Validators.required, Validators.email]),
      extension: new FormControl((employee !== undefined) ? employee.extension : ''),
      officePhone: new FormControl((employee !== undefined) ? employee.officePhone : ''),
      fax: new FormControl((employee !== undefined) ? employee.fax : ''),
      cellPhone: new FormControl((employee !== undefined) ? employee.cellPhone : ''),
      areasOfResponsibility: new FormControl((employee !== undefined) ? employee.areasOfResponsibility : []), // string[];
      title: new FormControl((employee !== undefined) ? employee.title : ''),
    });

  }

  async submitNewEmployee(corpEmpFormGroup: any) {
    const newCorpEmpFromServer = await this.corpEmpService.submitNewEmployee(corpEmpFormGroup.value);
    this.closeDialog(newCorpEmpFromServer);
  }

  async submitAlteredEmployee(corpEmpFormGroup: any) {
    const updatedCorpEmpFromServer = await this.corpEmpService.submitAlteredEmployee(corpEmpFormGroup.value);
    this.closeDialog(updatedCorpEmpFromServer);
  }

  async deleteEmployee(corpEmpFormGroup: any) {
    const deletedCorpEmp = await this.corpEmpService.deleteEmployee(corpEmpFormGroup.value);
    this.closeDialog(deletedCorpEmp);
  }

  async getSingleEmployee(employee: CorpEmployee) {
    const corpEmpFromDB = await this.corpEmpService.getSingleEmployee(employee._id);
  }

  filterDepartments(event: { query: any; }) {
    const filtered : string[] = [];
    const query = event.query;
    for (let index = 0; index < this.departmentList.length; index++) {
      const department = this.departmentList[index];
      if (department.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(department);
      }
    }
    this.filteredDepartments = filtered;
  }

  filterEmployees(event: {query: any}) {
    const filtered: CorpEmployee[] = [];
    const query = event.query;
    for (let index = 0; index < this.config.data.employees.length; index++) {
      const employee = this.config.data.employees[index];
      if (employee.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(employee);
      }
    }
    this.filteredEmployees = filtered;
  }

  async chooseEmployeeForSuggestion(chosenEmployee: any) {
    this.showSuggestDialog(this.chosenEmployee);
    this.closeDialog2(chosenEmployee);
  }

  showSuggestDialog(chosenEmployee?: CorpEmployee) {
    const corpEmpData = {
      employee: (chosenEmployee) ? this.initCorpEmpGroupObject(chosenEmployee) : this.initCorpEmpGroupObject(),
      dialogType: 'suggestion',
    };

    this.ref = this.dialogService.open(CorporateDirectoryDialogComponent, {
      data: corpEmpData,
      showHeader: true,
      header: 'Suggest Changes',
      width: 'auto',
      closable: true,
      closeOnEscape: true,
      dismissableMask: true,
      footer: 'Suggestions will be submitted for review',
    });

    this.ref.onClose.subscribe((employee: any) => {
      if (employee) {
        this.messageService.add({
          severity:'info',
          summary: 'Suggestion emailed to admin',
        });
      }
    });
  }

  async submitSuggestion(suggestionFormGroup: any) {
    const suggestionResponse = await this.corpEmpService.emailSuggestedEdit(suggestionFormGroup);
    this.closeDialog(suggestionResponse);
  }

  closeDialog(result?: any) {
    this.ref.close(result);
  }

  closeDialog2(result?: any) {
    this.ref2.close(result);
  }

  consoleLogFromPage(x) {
    console.log(x);
  }

}
