import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'DIRECTORIES';
  user: string;
  homeURL = environment.homeURL;
  directoryLogo = environment.directoryLogo;

  constructor(private keycloakService: KeycloakService) {}

  async ngOnInit() {
    this.getUserName();
  }

  signOut() {
    this.keycloakService.logout();
  }

  async getUserName() {
    const profile = await this.keycloakService.loadUserProfile();
    this.user = `${profile.firstName} ${profile.lastName}`;
  }

}
