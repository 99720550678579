<p-toast [style]="{marginTop: '80px'}"></p-toast>
<small style="padding-left: 2%; padding-bottom:12px;">Click store number to view details</small>
  <div class="store-directory-container">
  
      <div class="p-mx-auto" style="width:96%;">
        <p-table #dt [value]="stores" styleClass="p-datatable-gridlines" [paginator]="!disablePagination" [rows]="250" 
        [rowsPerPageOptions]="[25,50,150,250]" pageLinks="5" [globalFilterFields]="searchableFields"> 
          <ng-template pTemplate="caption">

            <p-toolbar>
              <div class="p-toolbar-group-left">
                <span *ngIf="userCanEdit">Edit Mode</span>
                <p-inputSwitch [(ngModel)]="editMode"  *ngIf="userCanEdit"></p-inputSwitch>
                <button pButton type="button" label="Create New" icon="far fa-plus-square" iconPos="left" (click)="showCreateDialog()"
                  class="p-button-rounded" *ngIf="userCanEdit"></button>
                <button pButton type="button" label="Export Directory" icon="far fa-download" iconPos="left" (click)="exportDirectory()"
                  class="p-button-rounded"></button>
              </div>
              <div class="p-toolbar-group-right">
                <i class="far fa-search" style="margin:4px 4px 0 0"></i>
                <input type="text" pInputText size="30" placeholder="Search Store Directory" (input)="dt.filterGlobal($event.target.value, 'contains')" style="height: 100%">
                <span>Show All</span>
                <p-inputSwitch [(ngModel)]="disablePagination"></p-inputSwitch>
              </div>
            </p-toolbar>
            
        </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="sortNumber" style="width:8em;text-align:center;">
                STORE # 
                <i class="fal fa-sort-alt" style="float: right;"></i>
              </th>
              <th pSortableColumn="storeName" style="text-align:center;">
                STORE NAME 
                <i class="fal fa-sort-alt" style="float: right;"></i>
              </th>
              <th pSortableColumn="storePhone" style="text-align:center;">
                STORE PHONE  
                <i class="fal fa-sort-alt" style="float: right;"></i>
              </th>
              <th pSortableColumn="storeEmail" class="md-screen" style="text-align:center;">
                STORE EMAIL  
                <i class="fal fa-sort-alt" style="float: right;"></i>
              </th>
              <th pSortableColumn="storeManager" class="lg-screen" style="text-align:center;">
                MANAGER 
                <i class="fal fa-sort-alt" style="float: right;"></i>
              </th>
              <th pSortableColumn="managerCell" class="xl-screen" style="text-align:center;">
                MANAGER PHONE  
                <i class="fal fa-sort-alt" style="float: right;"></i>
              </th>
              <th pSortableColumn="managerEmail" class="xl-screen" style="text-align:center;">
                MANAGER EMAIL  
                <i class="fal fa-sort-alt" style="float: right;"></i>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-tableIndex>
            <tr>
              <td>
                <button pButton type="button" class="p-button-outlined" label="{{tableIndex.storeNumber}} "
                (click)="showReadDialog(tableIndex._id)" [ngStyle]="{'width':toggleButtonWidth()}" ></button>
                <button pButton type="button" class="p-button-outlined" label="Edit " *ngIf="editMode === true"
                (click)="showEditDialog(tableIndex._id)" style="width: 50%;color: white; background-color: red;"></button>
              </td>
              <td style="text-align: center; font-weight:bold;">{{tableIndex.storeName}}</td>
              <td style="text-align: center; font-weight:bold;"><a href="tel:{{tableIndex.storePhone}}">{{tableIndex.storePhone | phone:"US"}}</a></td>
              <td style="text-align: center; font-weight:bold;" class="md-screen p-text-truncate"><a href="mailto:{{tableIndex.storeEmail}}">{{tableIndex.storeEmail}}</a></td>
              <td style="text-align: center; font-weight:bold;" class="lg-screen">{{tableIndex.storeManager}}</td>
              <td style="text-align: center; font-weight:bold;" class="xl-screen"><a href="tel:{{tableIndex.managerCell}}">{{tableIndex.managerCell | phone:"US"}}</a></td>
              <td style="text-align: center; font-weight:bold;" class="xl-screen p-text-truncate" ><a href="mailto:{{tableIndex.managerEmail}}">{{tableIndex.managerEmail}}</a></td>
            </tr>
          </ng-template>
        <!--  <ng-template pTemplate="summary">
            Footer
          </ng-template>-->
        </p-table>
        <blockquote style="font-size:18px; text-align: center; line-height: 1.5; font-style: italic;">If you see any information that needs to be changed, please fill out a directory change request.
          This information will be used to populate internal directories, Google listings for stores, and the
           Southern Tire Mart website.
        </blockquote>
        <div class="suggest-button-container">
          <button pButton type="button" icon="fas fa-comment-exclamation" iconPos="right" label="SUBMIT DIRECTORY CHANGE REQUEST" class="p-button-text suggest-button"
            (click)="showSuggestPrompt()"></button>
        </div>
      </div>
    <!--</form>-->
</div>
