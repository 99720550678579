import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {

  transform(phoneValue: number | string, country: string): any {
    try {
      if (phoneValue === null || phoneValue === '') { return ''; }
      const phoneNumber = parsePhoneNumber(`${phoneValue} `, country as CountryCode);
      return phoneNumber.formatNational();
    } catch (error) {
      console.error(error);
      return '';
    }
  }
}
