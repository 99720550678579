import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from './stores.interface';
import { environment } from '../../environments/environment';

@Injectable()
export class StoreService {

  private apiURL = `${environment.apiURL}/stores`;

  constructor(private http: HttpClient) { }

  async getStores() {
    return this.http.get<any>(this.apiURL)
      .toPromise()
      .then(res => <Store[]>res)
      .then((data) => { return data; });

    // const stores: Store[] = fakeData;
    // return Promise.resolve(stores);
  }

  async getSingleStore(storeID: string) {
    return this.http.get<any>(`${this.apiURL}/${storeID}`)
    .toPromise()
    .then(res => <Store>res)
    .then((data) => { return data; });
  }

  async submitNewStore(storeJSON: any) {
    return this.http.post<any>(`${this.apiURL}`, storeJSON)
      .toPromise()
      .then(res => <Store>res)
      .then((data) => { return data; });
  }

  async submitAlteredStore(storeJSON: any) {
    return this.http.put<any>(`${this.apiURL}/${storeJSON._id}`, storeJSON)
      .toPromise()
      .then(res => <Store>res)
      .then((data) => { return data; });
  }

  async deleteStore(storeJSON: any) {
    return this.http.delete<any>(`${this.apiURL}/${storeJSON._id}`)
      .toPromise()
      .then((res) => { return res; });
  }

  async emailSuggestedEdit(storeJSON: any) {
    return this.http.post<any>(`${this.apiURL}/contact_us`, storeJSON)
      .toPromise()
      .then(res => <Store>res)
      .then((data) => { return data; });
  }

  async exportToExcel() {
    const downloadLink = document.createElement('a');
    downloadLink.href = `${this.apiURL}/export`;
    downloadLink.target = '_blank';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}
