<div class="bg">
  <header>
      <div style="width:100%">
      <a role="button" (click)="signOut()">Logoff</a>
      <a style="padding: 10px 0px !important;color: black;">|</a>
      <a role="button" href="{{homeURL}}">Return To Portal</a>
      <a style="padding: 10px 0px !important;color: black;">|</a>
      <a role="menuitem" style="color: black">{{user}}</a>
      </div>
    <div class="center" style="width:100%" >
      <img src="{{directoryLogo}}" style="width:50%" >
    </div>
  </header>
  <div >
    <div style="padding-left: 2%; padding-bottom:2px;">
      <button pButton routerLink="/stores" class="p-button-text nav-button" label="Stores"></button> 
      <button pButton routerLink="/corporate" class="p-button-text nav-button" label="Corp"></button>  
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
