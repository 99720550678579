<p-toast [style]="{marginTop: '80px'}"></p-toast>
<div class="corporate-directory-container">

  <div class="p-mx-auto" style="width:96%">
    <p-table #dt [value]="corpEmployees" styleClass="p-datatable-gridlines" [paginator]="true" [rows]="10" 
    [rowsPerPageOptions]="[10,15,20,150]" pageLinks="5" [globalFilterFields]="searchableFields">
    <ng-template pTemplate="caption">

      <p-toolbar>
        <div class="p-toolbar-group-left">
          <span  *ngIf="userCanEdit">Edit Mode</span>
          <p-inputSwitch [(ngModel)]="editMode"  *ngIf="userCanEdit"></p-inputSwitch>
          <button pButton type="button" label="Create New" icon="far fa-plus-square" iconPos="left" (click)="showCreateDialog()"
            class="p-button-rounded"  *ngIf="userCanEdit"></button>
          <button pButton type="button" label="Export Directory" icon="far fa-download" iconPos="left" (click)="exportDirectory()"
            class="p-button-rounded"></button>
        </div>
        <div class="p-toolbar-group-right">
          <i class="far fa-search" style="margin:4px 4px 0 0"></i>
          <input type="text" pInputText size="30" placeholder="Search Corporate Directory" (input)="dt.filterGlobal($event.target.value, 'contains')" style="height: 100%">
        </div>
      </p-toolbar>

    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width:8em">
          
        </th>
        <th pSortableColumn="name" style="width:10em;text-align:center;">
          NAME 
          <i class="fal fa-sort-alt" style="float: right;"></i>
        </th>
        <th pSortableColumn="officePhone" style="width:10em;text-align:center;">
          OFFICE PHONE  
          <i class="fal fa-sort-alt" style="float: right;"></i>
        </th>
        <th pSortableColumn="extension" class="md-screen" style="width:9em;text-align:center;">
          EXTENSION  
          <i class="fal fa-sort-alt" style="float: right;"></i>
        </th>
        <th pSortableColumn="email" class="lg-screen" style="width:15em;text-align:center;">
          EMAIL 
          <i class="fal fa-sort-alt" style="float: right;"></i>
        </th>
        <th pSortableColumn="cellPhone" class="lg-screen" style="width:10em;text-align:center;">
          CELL PHONE  
          <i class="fal fa-sort-alt" style="float: right;"></i>
        </th>
        <th pSortableColumn="fax" class="xl-screen" style="text-align:center;" >
          RESPONSIBILITIES  
          <i class="fal fa-sort-alt" style="float: right;"></i>
        </th>
        <th pSortableColumn="department" class="xl-screen" style="width:15em;text-align:center;">
          DEPARTMENT  
          <i class="fal fa-sort-alt" style="float: right;"></i>
        </th>
      </tr>
      
    </ng-template>
    <ng-template pTemplate="body" let-tableIndex>
      <tr>
        <td >
          <button pButton type="button" class="p-button-outlined" label="View"
                (click)="showReadDialog(tableIndex._id)" [ngStyle]="{'width':toggleButtonWidth()}" ></button>
                <button pButton type="button" class="p-button-outlined" label="Edit " *ngIf="editMode === true"
                (click)="showEditDialog(tableIndex._id)" style="width: 50%;color: white; background-color: red;"></button>
        </td>
        <td style="text-align: center; font-weight:bold;">{{tableIndex.name}}</td>
        <td style="text-align: center; font-weight:bold;"><a href="tel:{{tableIndex.officePhone}}">{{tableIndex.officePhone | phone:"US"}}</a></td>
        <td style="text-align: center; font-weight:bold;" class="md-screen">{{tableIndex.extension}}</td>
        <td style="text-align: center; font-weight:bold;" class="lg-screen p-text-truncate"><a href="mailto:{{tableIndex.email}}">{{tableIndex.email}}</a></td>
        <td style="text-align: center; font-weight:bold;" class="lg-screen"><a href="tel:{{tableIndex.cellPhone}}">{{tableIndex.cellPhone | phone:"US"}}</a></td>
        <td style="text-align: center; font-weight:bold;" class="xl-screen p-text-truncate">{{tableIndex.areasOfResponsibility}}</td>
        <td style="text-align: center; font-weight:bold;" class="xl-screen">{{tableIndex.department}}</td>
      </tr>
      
    </ng-template>
    </p-table>
    <blockquote style="font-size:18px; text-align: center; line-height: 1.5; font-style: italic;">
      If you see any information that needs to be changed, please fill out a directory change request.
      This information will be used to populate internal directories, Google listings for stores, and the
       Southern Tire Mart website.
    </blockquote>
    <div class="suggest-button-container">
      <button pButton type="button" icon="fas fa-comment-exclamation" iconPos="right" label="SUBMIT DIRECTORY CHANGE REQUEST" class="p-button-text suggest-button"
        (click)="showSuggestPrompt()"></button>
    </div>
  </div>

</div>
