<div class="store-directory-dialog-container">
  <form [formGroup]="dialogForm">
    <div *ngIf="config.data.dialogType === 'readOnly'" class="readOnly" [formGroup]="config.data.store">
      
      <div class="p-grid "><!--First Row-->

        <div class="p-lg-4 p-md-4 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Store Number: </div>
          <div class="box p-text-center p-text-bold">{{config.data.store.controls.storeNumber.value}}</div>
        </div>

        <div class="p-lg-4 p-md-4 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Store Name: </div>
          <div class="box p-text-center p-text-bold">{{config.data.store.controls.storeName.value}}</div>
        </div>

        <div class="p-lg-4 p-md-4 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Store Email: </div> 
          <div class="box p-text-truncate p-text-center p-text-bold"><a href="mailto:{{config.data.store.controls.storeEmail.value}}"
            >{{config.data.store.controls.storeEmail.value}}</a></div>
        </div>

      </div><!--First Row-->

      <div class="p-grid"><!--Second Row-->

        <div class="p-lg-6 p-md-6 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Mailing Address</div>
          <div class="box p-text-wrap p-text-center p-text-bold"
          >{{config.data.store.controls.streetAddress.value}}, {{config.data.store.controls.city.value}}, 
          {{config.data.store.controls.state.value}} {{config.data.store.controls.zipCode.value}}</div>
        </div>

        <div class="p-lg-3 p-md-3 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Store Phone: </div>
          <div class="box p-text-center p-text-bold"><a href="tel:{{config.data.store.controls.storePhone.value}}">
            {{config.data.store.controls.storePhone.value | phone:"US"}}</a></div>
        </div>

        <div class="p-lg-3 p-md-3 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Store Fax: </div>
          <div class="box p-text-center p-text-bold">{{config.data.store.controls.storeFax.value | phone:"US"}}</div>
        </div>
        
      </div><!--Second Row-->

      <div class="p-grid"><!--Third Row-->

        <div class="p-lg-6 p-md-6 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Location: </div>
          <div class="box p-text-center p-text-bold">{{config.data.store.controls.location.value}}</div>
        </div>

        <div class="p-lg-3 p-md-3 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Weekday Hours: </div>
          <div class="box p-text-center p-text-bold">{{config.data.store.controls.weekdayHours.value}}</div>
        </div>

        <div class="p-lg-3 p-md-3 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Weekend Hours: </div>
          <div class="box p-text-center p-text-bold">{{config.data.store.controls.weekendHours.value}}</div>
        </div>

      </div><!--Third Row-->

      <div class="p-grid"><!--Fourth Row-->

        <div class="p-lg-6 p-md-6 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Store Manager: </div>
          <div class="box p-text-center p-text-bold">{{config.data.store.controls.storeManager.value}}</div>
        </div>

        <div class="p-lg-6 p-md-6 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Manager Email: </div>
          <div class="box p-text-truncate p-text-center p-text-bold"><a href="mailto:{{config.data.store.controls.managerEmail.value}}">
            {{config.data.store.controls.managerEmail.value}}</a></div>
        </div>

      </div><!--Fourth Row-->

      <div class="p-grid"><!--Fifth Row-->
        
        <div class="p-lg-6 p-md-6 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Manager Cell: </div>
          <div class="box p-text-center p-text-bold"><a href="tel:{{config.data.store.controls.managerCell.value}}">
            {{config.data.store.controls.managerCell.value | phone:"US"}}</a></div>
        </div>

        <div class="p-lg-2 p-md-2 p-sm-6 p-col-6" *ngIf="clientDomain !== 'STMP'">
          <div class="p-text-left p-text-bold">Retread: </div>
          <p-checkbox [formControl]="config.data.store.controls.retread" 
            [binary]="true" [readonly]="true"></p-checkbox> 
        </div>

        <div class="p-lg-3 p-md-3 p-sm-6 p-col-6" *ngIf="clientDomain !== 'STMP'">
          <div class="p-text-left p-text-bold">Parent Store#: </div>
          <div class="box p-text-center p-text-bold">{{config.data.store.controls.retreadParent.value}}</div>
        </div>

      </div><!--Fifth Row-->

      <div class="p-grid"><!--Sixth Row-->
        
        <div class="p-lg-2 p-sm-6" *ngIf="clientDomain !== 'STMP'">
          <div class="p-text-left p-text-bold">Retail: </div>
          <p-checkbox [formControl]="config.data.store.controls.retail" 
            [binary]="true" [readonly]="true"></p-checkbox>
        </div>

        <div class="p-lg-2 p-sm-6" *ngIf="clientDomain !== 'STMP'">
            <div class="p-text-left p-text-bold">Maint+: </div>
            <p-checkbox [formControl]="config.data.store.controls.maintPlus" 
              [binary]="true" [readonly]="true"></p-checkbox>
        </div>

        <div class="p-lg-2 p-sm-6" *ngIf="clientDomain !== 'STMP'">
          <div class="p-text-left p-text-bold">Commercial: </div>
          <p-checkbox [formControl]="config.data.store.controls.commercial" 
            [binary]="true" [readonly]="true"></p-checkbox> 
        </div>

      </div><!--Sixth Row-->
      <div class="p-grid" *ngIf="userCanEdit"><!--Marketing Only Seventh Row-->

        <div class="p-lg-6 p-md-6 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Website Quote Email: </div>
          <div class="box p-text-center p-text-bold">{{config.data.store.controls.websiteQuoteEmail.value}}</div>
        </div>

        <div class="p-lg-6 p-md-6 p-sm-12 p-col-12">
          <div class="p-text-left p-text-bold">Website Quote Forwards: </div>
          <div class="box p-text-center p-text-bold">{{config.data.store.controls.websiteQuoteForwards.value}}</div>
        </div>

      </div><!--Marketing Only Seventh Row-->
    
    </div>
  <!--^^^ Read Only| vvv Editable-->
    <div *ngIf="config.data.dialogType === 'edit' || 
      config.data.dialogType === 'suggestion' || 
      config.data.dialogType === 'create'" class="edit" [formGroup]="config.data.store">
      <div class="p-grid" style="padding-top: 5px">
        <div class="p-lg-4 p-md-4 p-sm-12 p-col-12">
          <label for="storeNumber">Store Number
            <span style="color:red">*</span>
          </label>
          <label for="storeNumber" *ngIf="!config.data.store.controls.storeNumber.valid" style="color: red">   Must only contain digits(0-9)</label>
          <input type="text" pInputText formControlName="storeNumber"> 
        </div>


        <div class="p-lg-4 p-md-4 p-sm-12 p-col-12">
          <label for="storeName">Store Name
            <span style="color:red">*</span>
          </label>
          <input type="text" pInputText formControlName="storeName"> 
        </div>


        <div class="p-lg-4 p-md-4 p-sm-12 p-col-12">
          <label for="storeEmail">Store Email
            <span style="color:red">*</span>
          </label>
          <label for="storeEmail" *ngIf="!config.data.store.controls.storeEmail.valid" style="color: red">   Must follow: user@domain.com</label>
          <input type="text" pInputText formControlName="storeEmail"> 
        </div>

      </div>

      <div class="p-grid">

        <div class="p-lg-5 p-md-5 p-sm-12 p-col-12">
          <label for="streetAddress">Street Address
            <span style="color:red">*</span>
          </label>
          <input type="text" pInputText formControlName="streetAddress"> 
        </div>


        <div class="p-lg-3 p-md-3 p-sm-6 p-col-6">
          <label for="city">City
            <span  style="color:red">*</span>
          </label>
          <input type="text" pInputText formControlName="city"> 
        </div>


        <div class="p-lg-2 p-md-2 p-sm-6 p-col-6">
          <label for="state">State
            <span style="color:red">*</span>
          </label>
          <input type="text" pInputText formControlName="state"> 
        </div>


        <div class="p-lg-2 p-md-2 p-sm-6 p-col-6">
          <label for="zipCode">Zip
            <span style="color:red">*</span>
          </label>
          <input type="text" pInputText formControlName="zipCode"> 
        </div>

      </div>

      <div class="p-grid"><!-- Radio row-->
        <div class="p-lg-3 p-md-3 p-sm-6 p-col-6">
          <div>
            <label for="storePhone">Store Phone</label>
          </div>
          <p-inputMask formControlName="storePhone" mask="(999) 999-9999" class="input-mask" unmask="true"></p-inputMask>
        </div>

        <div class="p-lg-3 p-md-3 p-sm-6 p-col-6">
          <div>
            <label for="storeFax">Store Fax</label>
          </div>
          <p-inputMask formControlName="storeFax" mask="(999) 999-9999" class="input-mask" unmask="true"></p-inputMask>
        </div>

        <div class="p-lg-1 p-md-1 p-sm-6 p-col-6" *ngIf="clientDomain !== 'STMP'">
          <div>
            <label for="retail">Retail</label>
          </div>
            <p-inputSwitch formControlName="retail" ></p-inputSwitch>
        </div>

        <div class="p-lg-1 p-md-1 p-sm-6 p-col-6" *ngIf="clientDomain !== 'STMP'">
          <div>
            <label for="maintPlus">Maint+</label>
           </div>
            <p-inputSwitch formControlName="maintPlus"></p-inputSwitch>
        </div>

        <div class="p-lg-1 p-md-1 p-sm-6 p-col-6" *ngIf="clientDomain !== 'STMP'">
          <div>
            <label for="retread">Retread</label>
          </div>
            <p-inputSwitch formControlName="retread"></p-inputSwitch> 
        </div>

        <div class="p-lg-1 p-md-1 p-sm-6 p-col-6" *ngIf="config.data.store.controls.retread.value === true">
          <div>
            <label for="retreadParent">Parent Store#</label>
          </div>
            <!-- <input type="text" pInputText formControlName="retreadParent" style="height:50%"> -->
            <p-inputMask formControlName="retreadParent" mask="(999)" size="5"></p-inputMask>
        </div>

        <div class="p-lg-1 p-md-1 p-sm-6 p-col-6" *ngIf="clientDomain !== 'STMP'">
          <div>
            <label for="commercial">Commercial</label>
          </div>
            <p-inputSwitch formControlName="commercial"></p-inputSwitch> 
        </div>
      </div><!-- Radio row-->

      <div class="p-grid">

        <div class="p-lg-6 p-md-6 p-sm-12 p-col-12">
          <label for="location">Location</label>
          <input type="text" pInputText formControlName="location"> 
        </div>


        <div class="p-lg-3 p-md-3 p-sm-12 p-col-12">
          <label for="weekdayHours">Weekday Hours</label>
          <input type="text" pInputText formControlName="weekdayHours"> 
        </div>


        <div class="p-lg-3 p-md-3 p-sm-12 p-col-12">
          <label for="weekendHours">Weekend Hours</label>
          <input type="text" pInputText formControlName="weekendHours"> 
        </div>

      </div>

      <div class="p-grid">
        <div class="p-lg-4 p-md-4 p-sm-12 p-col-12">
          <label for="storeManager">Store Manager</label>
          <input type="text" pInputText formControlName="storeManager"> 
        </div>


        <div class="p-lg-5 p-md-5 p-sm-12 p-col-12">
          <label for="managerEmail">Manager Email</label>
          <label for="managerEmail" *ngIf="!config.data.store.controls.managerEmail.valid" style="color: red">   Must follow: user@domain.com</label>
          <input type="text" pInputText formControlName="managerEmail"> 
        </div>

        <div class="p-lg-3 p-md-3 p-sm-12 p-col-12">
          <div>
          <label for="managerCell">Manager Cell</label>
        </div>
          <p-inputMask id="managerCell" formControlName="managerCell" mask="(999) 999-9999" class="input-mask" unmask="true"></p-inputMask>
        </div>

      </div>

      <div class="p-grid">
        <div class="p-lg-6 p-md-6 p-sm-12 p-col-12">
          <label for="websiteQuoteEmail">Website Quote Email</label>
          <input type="text" pInputText formControlName="websiteQuoteEmail"> 
        </div>
        <div class="p-lg-6 p-md-6 p-sm-12 p-col-12">
          <label for="websiteQuoteForwards">Website Quote Forwards</label>
          <!-- <input type="text" pInputText formControlName="websiteQuoteForwards" >  -->
          <textarea  style="width:100%;height:75%;" pInputTextarea autoResize="autoResize" formControlName="websiteQuoteForwards"></textarea>
        </div>
      </div>
           
    </div>
    <div class="ui-dialog-footer" style="text-align: right; margin-top:.5em;">
      <button pButton type="button" icon="far fa-skull-crossbones" iconPos="left" label="DELETE" (click)="deleteStore(config.data.store)"
        class="p-button-rounded" *ngIf="config.data.dialogType === 'edit'"></button>
      <button pButton type="button" icon="far fa-times" iconPos="left" label="Cancel" (click)="closeDialog()"
        class="p-button-rounded" *ngIf="config.data.dialogType === 'edit'" ></button>
      <button pButton type="button" icon="far fa-check" iconPos="left" label="Save" (click)="submitAlteredStore(config.data.store)"
        class="p-button-rounded" *ngIf="config.data.dialogType === 'edit'"></button>
      <button pButton type="button" icon="far fa-check" iconPos="left" label="Submit" (click)="submitSuggestion(config.data.store.value)"
        class="p-button-rounded" *ngIf="config.data.dialogType === 'suggestion'"></button>
      <button pButton type="button" icon="far fa-check" iconPos="left" label="Save" (click)="submitNewStore(config.data.store)"
        class="p-button-rounded" *ngIf="config.data.dialogType === 'create'"></button>
    </div>

    <div *ngIf="config.data.dialogType === 'suggestPrompt'" class="suggestPrompt">
      <div style="height:300px">
          <p-autoComplete placeholder="Search for Store Number" field="storeNumber" [(ngModel)]="chosenStore" [ngModelOptions]="{standalone: true}" [suggestions]="filteredStores"
          (completeMethod)="filterStores($event)" [forceSelection]="true" ></p-autoComplete>
        <button pButton type="button" label="OK" class="p-button-text" (click)="chooseStoreForSuggestion($event)"></button>
      </div>
    </div>

  </form>
</div>
